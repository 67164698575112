import Icon from '../Icon/Icon';
import { usePageContext } from 'context/hooks/usePageContext';
import React, { useEffect, useState } from 'react';

import { ReactComponent as SuccessLine } from '../../../assets/icons/success-outline.svg';
import { ReactComponent as SuccessFilled } from '../../../assets/icons/success-filled.svg';
import { PAGES } from 'context/pageContext';

interface ProgressBarProps {
  isPaymentComplete: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ isPaymentComplete }) => {
  const { changePage } = usePageContext();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isPaymentComplete) {
      setProgress(100);
      setTimeout(() => changePage(PAGES.SUCCESS), 1500);
    } else {
      setProgress(70);
    }
  }, [isPaymentComplete, changePage]);

  return (
    <>
      <div className="flex justify-between items-center gap-1 mb-3 mt-8 relative">
        <div className="flex flex-col items-center justify-center text-success-400">
          <Icon svg={SuccessLine} size={30} className="m-auto" />
          <p>Paid</p>
        </div>
        <div
          className={`h-1 border-t-4 border-collapse w-[76%] border-dashed absolute left-[calc(50%-0.66rem)] 
          transform -translate-x-1/2 -translate-y-1/2 top-4 ${
            isPaymentComplete ? 'border-t-success-400' : 'border-t-[#e4e7ec]'
          }`}
        />
        <div
          className={`flex flex-col items-center justify-center ${
            isPaymentComplete ? 'text-success-400' : 'text-[#e4e7ec]'
          }`}
        >
          <Icon
            svg={SuccessFilled}
            size={30}
            className={`fill-current ${
              isPaymentComplete ? 'text-success-400' : 'text-[#e4e7ec]'
            }`}
          />
          <p>Confirmed</p>
        </div>
      </div>
      <div className="lg:p-2 border border-[#E3E3E3] bg-[#f4f4f4] rounded-3xl">
        <div className="w-full h-5 rounded-xl bg-gray-200 overflow-hidden relative">
          <div
            className={`h-full transition-all duration-500 rounded-xl ease-out animate-pulse ${
              isPaymentComplete ? 'bg-green-500' : 'bg-blue-500'
            }`}
            style={{ width: `${progress}%` }}
          >
            <div
              className={`absolute top-0 left-0 h-full bg-gradient-to-r ${
                isPaymentComplete
                  ? 'from-green-300 via-green-400 to-green-500'
                  : 'from-blue-300 via-blue-400 to-blue-500'
              }`}
              style={{
                width: '100%',
                transform: `translateX(-${100 - progress}%)`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
