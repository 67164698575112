import { useErrorContext } from 'context/hooks/useErrorContext';
import { usePageContext } from 'context/hooks/usePageContext';
import { useState, useEffect, useCallback } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { checkResponse } from 'utils';
import { PAGE_SUB } from 'context/pageContext';

const useGetTransferStatus = (paymentId: string) => {
  const { setError: updateError } = useErrorContext();
  const { page, changePage, changeSub } = usePageContext();
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);

  const triggerFetch = useCallback(() => {
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    if (!shouldFetch) return;

    const fetchPaymentStatus = async () => {
      try {
        const response = await axiosInstance.get(`/transactions/${paymentId}`);
        setStatus(response.data.transactionStatus);
        checkResponse(response.data.transactionStatus, changeSub, changePage);
      } catch (err) {
        setError(true);
        updateError(err as Record<string, unknown>);
        changeSub(PAGE_SUB.ERROR);
        setShouldFetch(false);
      } finally {
        setLoading(false);
        setShouldFetch(false);
      }
    };

    fetchPaymentStatus();
  }, [changePage, changeSub, page, paymentId, shouldFetch, updateError]);

  return { status, loading, error, triggerFetch };
};

export default useGetTransferStatus;
