import React from 'react';
import { usePageContext } from 'context/hooks/usePageContext';
import { useErrorContext } from 'context/hooks/useErrorContext';
import PayWithCard from './pages/PayWithCard';
import PayWithTransfer from './pages/PayWithTransfer';
import { FC } from 'react';
import Icon from './ui/Icon/Icon';
import { formatCurrency } from 'utils';
import Success from './screens/Success';

import { ReactComponent as CreditCard } from '../assets/icons/creditcard.svg';
import { ReactComponent as Transfer } from '../assets/icons/transfer.svg';
import { ReactComponent as Cancel } from '../assets/icons/cancel.svg';
import { PAGE_SUB, PAGES } from 'context/pageContext';

export interface SwitcherProps extends React.HTMLAttributes<HTMLDivElement> {
  amount: number;
  email?: string;
  initiateTransfer?: () => void;
}

const PageSwitcher: FC<SwitcherProps> = ({ amount, email }) => {
  const { page, sub, changePage } = usePageContext();
  const { errorBool } = useErrorContext();

  const handleChange = () => {
    changePage();
  };

  return (
    <div>
      <div
        className={`bg-white rounded-xl w-full py-8 flex mx-auto flex-col items-center shadow-xl max-lg:hidden ${errorBool ? 'border-red-600 border-2' : ''
          }`}
      >
        {page === PAGES.CARD ? (
          <PayWithCard amount={amount} email={email} />
        ) : page === PAGES.TRANSFER ? (
          <PayWithTransfer amount={amount} />
        ) : null}
      </div>

      {/* mobile */}
      <div className={'lg:hidden h-screen max-h-[100vh] bg-white space-y-4'}>
        {page === PAGES.SUCCESS && (
          <div className="h-screen w-screen p-6 flex flex-col items-center justify-center">
            <Success amount={amount} />
          </div>
        )}
        <div className="flex items-center gap-2 text-[#0284F8] bg-[#C9E5FF] p-4 cursor-pointer w-full lg:hidden">
          <Icon
            size={16}
            svg={page === PAGES.CARD ? CreditCard : Transfer}
            className="fill-current"
          />
          {page === PAGES.CARD ? 'Pay with Card' : 'Pay with Transfer'}
        </div>
        <div className="text-center p-5 bg-[#C9E5FF] rounded-sm w-full max-w-xs m-auto">
          <p>You are about to pay:</p>
          <span className="font-bold text-2xl text-[#0284F8] pt-1">
            {formatCurrency(amount / 100, 'NGN')}
          </span>
        </div>
        {page === PAGES.CARD ? (
          <PayWithCard amount={amount} />
        ) : (
          <PayWithTransfer amount={amount} />
        )}
        {(page === PAGES.CARD || page === PAGES.TRANSFER) &&
          (sub === PAGE_SUB.CARD || sub === PAGE_SUB.TRANSFER) && (
            <div className="pt-[20%]">
              <button
                className="flex items-center gap-1 border border-[#f5f5f5] p-2 text-[#2E3C4E] bg-[#e4e4e4] text-sm text-center
        font-semibold rounded-md mx-auto"
                onClick={handleChange}
              >
                <Icon size={20} svg={Cancel} className="text-[#2E3C4E]" />
                Change Payment Method
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default PageSwitcher;
