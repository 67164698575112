import Icon from '../Icon/Icon';
import { usePageContext } from 'context/hooks/usePageContext';
import { PAGES } from 'context/pageContext';
import React, { FC } from 'react';

interface PaymentSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  name: PAGES;
  icon: any;
  handlePageChange?: (name: PAGES) => void;
}

const PaymentSelector: FC<PaymentSelectorProps> = ({
  name,
  icon,
  handlePageChange,
  onClick,
  ...props
}) => {
  const { page } = usePageContext();

  return (
    <div
      className={`flex items-center gap-2 pl-4 p-4 cursor-pointer rounded-lg 
        font-600 ${
          page === name
            ? 'bg-[#C9E5FF] text-[#0284F8]'
            : 'text-[#121212] hover:bg-[#0284F8]/60 hover:text-white'
        }`}
      onClick={(e) => {
        if (handlePageChange) handlePageChange(name);
        if (onClick) onClick(e);
      }}
    >
      <Icon size={16} svg={icon} className="fill-current" />
      Pay with {name}
    </div>
  );
};

export default PaymentSelector;
