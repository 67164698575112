import { SwitcherProps } from 'components/PageSwitcher';
import { usePageContext } from 'context/hooks/usePageContext';
import { FC } from 'react';
import CardComponent from './cardScreens/CardComponent';
import PinAuth from './cardScreens/PinAuth';
import OtpAuth from './cardScreens/OtpAuth';
import PhoneAuth from './cardScreens/PhoneAuth';
import BirthdayAuth from './cardScreens/BirthdayAuth';
import AddressAuth from './cardScreens/AddressAuth';
import Timeout from 'components/screens/Timeout';
import ErrorComponent from 'components/screens/Error';
import { PAGE_SUB } from 'context/pageContext';

const PayWithCard: FC<SwitcherProps> = ({ amount, email }) => {
  const { sub } = usePageContext();

  return (
    <section
      style={{ fontFamily: 'Inter, sans-serif' }}
      className="w-full md:max-w-sm m-auto space-y-4"
    >
      {sub === PAGE_SUB.CARD && <CardComponent amount={amount} email={email} />}
      {sub === PAGE_SUB.PIN_AUTH && <PinAuth />}
      {sub === PAGE_SUB.OTP_AUTH && <OtpAuth />}
      {sub === PAGE_SUB.PHONE_AUTH && <PhoneAuth />}
      {sub === PAGE_SUB.BIRTHDAY_AUTH && <BirthdayAuth />}
      {sub === PAGE_SUB.ADDRESS_AUTH && <AddressAuth />}
      {sub === PAGE_SUB.TIMEOUT && <Timeout />}
      {sub === PAGE_SUB.ERROR && <ErrorComponent />}
    </section>
  );
};

export default PayWithCard;
