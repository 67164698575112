import Button from 'components/ui/Button/Button';
import { usePageContext } from 'context/hooks/usePageContext';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { PAGE_SUB } from 'context/pageContext';
import useGetCardPaymentStatus from 'hooks/useGetCardPaymentStatus';
import React, { useState } from 'react';
import { checkResponse } from 'utils';
import axiosInstance from 'utils/axiosInstance';
import { validateBirthday } from 'utils/validation';

const BirthdayAuth: React.FC = () => {
  const { ceviantTransactionId } = useTransactionContext();
  const { changeSub, changePage } = usePageContext();
  const [birthday, setBirthday] = useState({ birthday: '' });
  const [error, setError] = useState<Record<string, string>>({});
  const [isBirthdaySubmitting, setIsBirthdaySubmitting] = useState(false);
  const { triggerFetch, error: statusError } =
    useGetCardPaymentStatus(ceviantTransactionId);

  const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthday({ ...birthday, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validateBirthday(birthday);
    if (validation.isValid) {
      try {
        setIsBirthdaySubmitting(true);

        const payload = {
          birthday: birthday.birthday,
          reference: ceviantTransactionId,
        };

        const response = await axiosInstance.post(
          '/payments/birthdayAuths',
          payload
        );

        if (response.data) {
          checkResponse(response.data.response, changeSub, changePage);
        }
      } catch (error) {
        triggerFetch();
      } finally {
        setIsBirthdaySubmitting(false);
      }
    } else {
      setError(validation.errors);
    }
  };

  if (statusError) {
    changeSub(PAGE_SUB.ERROR);
  }

  return (
    <div className="space-y-6 text-center py-14">
      <img src="/icons/authenticate.svg" alt="" className="m-auto w-11" />
      <h3 className="text-[#535353] text-lg font-semibold">
        Please input your phone number to continue your payment
      </h3>
      <form className="flex flex-col gap-5 px-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="birthday">Birthday:</label>
          <input
            type="date"
            name="birthday"
            value={birthday.birthday}
            onChange={handleBirthdayChange}
          />
        </div>
        {error.birthday && (
          <p className="text-red-500 text-sm mt-1">{error.birthday}</p>
        )}
        <div className="flex gap-3 w-full m-auto">
          <Button
            type="submit"
            variant="primary"
            disabled={isBirthdaySubmitting}
          >
            Authenticate
          </Button>
          <Button variant="outline" onClick={() => changeSub(PAGE_SUB.CARD)}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BirthdayAuth;
