import Button from 'components/ui/Button/Button';
import FloatingLabelInput from 'components/ui/Inputs/FloatingLabelInput';
import { usePageContext } from 'context/hooks/usePageContext';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { PAGE_SUB } from 'context/pageContext';
import useGetCardPaymentStatus from 'hooks/useGetCardPaymentStatus';
import React, { useState } from 'react';
import { checkResponse } from 'utils';
import axiosInstance from 'utils/axiosInstance';
import { validateOtp } from 'utils/validation';

const OtpAuth = () => {
  const { ceviantTransactionId } = useTransactionContext();
  const { changeSub, changePage } = usePageContext();
  const [otpForm, setOtpForm] = useState({ otp: '' });
  const [otpErrors, setOtpErrors] = useState<Record<string, string>>({});
  const [isOtpSubmitting, setIsOtpSubmitting] = useState(false);
  const { triggerFetch, error } = useGetCardPaymentStatus(ceviantTransactionId);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpForm({ ...otpForm, [e.target.name]: e.target.value });
  };

  const handleOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validateOtp(otpForm);
    if (validation.isValid) {
      try {
        setIsOtpSubmitting(true);
        const payload = {
          otp: otpForm.otp,
          reference: ceviantTransactionId,
        };

        const response = await axiosInstance.post(
          '/payments/otpAuths',
          payload
        );

        if (response.data) {
          checkResponse(response.data.response, changeSub, changePage);
        }
      } catch (error) {
        triggerFetch();
      } finally {
        setIsOtpSubmitting(false);
      }
    } else {
      setOtpErrors(validation.errors);
    }
  };

  if (error) {
    changeSub(PAGE_SUB.ERROR);
  }

  return (
    <div className="space-y-6 text-center py-14">
      <img src="/icons/authenticate.svg" alt="" className="m-auto w-11" />
      <h3 className="text-[#535353] text-lg font-semibold">
        Please input the OTP sent to your phone to continue your payment
      </h3>
      <form className="flex flex-col gap-5 px-4" onSubmit={handleOtpSubmit}>
        <FloatingLabelInput
          label="OTP"
          type="number"
          value={otpForm.otp}
          onChange={handleOtpChange}
          name="otp"
        />
        {otpErrors.otp && (
          <p className="text-red-500 text-sm mt-1">{otpErrors.otp}</p>
        )}
        <div className="flex gap-3 w-full m-auto">
          <Button type="submit" variant="primary" disabled={isOtpSubmitting}>
            Authenticate
          </Button>
          <Button variant="outline" onClick={() => changeSub(PAGE_SUB.CARD)}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OtpAuth;
