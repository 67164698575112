import Icon from '../ui/Icon/Icon';

import { ReactComponent as Retry } from '../../assets/icons/retry.svg';
import Button from '../ui/Button/Button';
import { FC, useState, useEffect } from 'react';
import { checkResponse } from 'utils';
import { usePageContext } from 'context/hooks/usePageContext';
import { useErrorContext } from 'context/hooks/useErrorContext';
import { PAGE_SUB, PAGES } from 'context/pageContext';

interface ErrorProps {
  retryString?: string;
}

type Metadata = {
  reference: string;
  message: string;
};

const ErrorComponent: FC<ErrorProps> = ({ retryString }) => {
  const { changePage, changeSub, page } = usePageContext();
  const { error, clearError } = useErrorContext();
  const [errMsg, setErrMsg] = useState<string>('');

  const defaultMsg =
    'Sorry, your request could not be processed. An error occurred, try again.';

  useEffect(() => {
    if (error !== null && error.metadata) {
      const metadata: Metadata = error.metadata as Metadata;
      switch (metadata.message) {
        case 'Declined':
          setErrMsg('Card Declined');
          return;
        case 'Token Not Generated. Customer Not Registered on Token Platform':
          setErrMsg('Token Not Generated');
          return;
        case 'Insufficient funds':
          setErrMsg('Insufficient Funds');
          return;
        default:
          setErrMsg(defaultMsg);
      }
    }
  }, [error]);

  const handleChange = () => {
    clearError();

    if (retryString) {
      checkResponse(retryString, changeSub, changePage);
    }

    if (page === PAGES.CARD) {
      changeSub(PAGE_SUB.CARD);
    }

    if (page === PAGES.TRANSFER) {
      changePage(PAGES.TRANSFER);
    }
  };

  return (
    <div className="space-y-20 text-center py-10 flex flex-col items-center px-4">
      <div className="flex flex-col items-center gap-4 w-full">
        <img src="./icons/error.svg" alt="error Message" className="w-20" />
        <div className="w-full text-center bg-red-200 rounded-lg border-4 border-dashed border-red-600 px-6 py-3">
          <p className="text-red-600 text-base font-semibold">
            {errMsg || defaultMsg}
          </p>
        </div>
      </div>

      <div className="flex gap-3 w-full m-auto">
        <Button
          type="submit"
          variant="primary"
          className="flex items-center justify-center gap-2 md:!text-lg"
          onClick={() => handleChange()}
        >
          <Icon svg={Retry} size={15} />
          Retry
        </Button>
        <Button
          variant="outline"
          className="md:!text-lg"
          onClick={() =>
            window.parent.postMessage({ message: 'closeCeviantSDK' }, '*')
          }
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ErrorComponent;
