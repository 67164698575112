import { useEffect } from 'react';
import ProgressBar from 'components/ui/ProgressBar/ProgressBar';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { usePageContext } from 'context/hooks/usePageContext';
import useGetTransferStatus from 'hooks/useGetTransferStatus';
import { PAGE_SUB } from 'context/pageContext';

const Payment = () => {
  const { ceviantTransactionId } = useTransactionContext();
  const { changeSub } = usePageContext();
  const { status, error, triggerFetch } = useGetTransferStatus(
    ceviantTransactionId.slice(8)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      triggerFetch();
    }, 15000);

    return () => clearTimeout(timer);
  }, [triggerFetch]);

  if (error) {
    changeSub(PAGE_SUB.ERROR);
  }

  return (
    <div className="text-center space-y-16 lg:pb-12">
      <div className="space-y-4">
        <ProgressBar isPaymentComplete={status === 'APPROVED' ? true : false} />
        <div className="text[#535353]">
          <h2 className="text-lg lg:text-xl font-semibold">
            {status === 'APPROVED' ? 'Confirmed' : 'Verifying...'}
          </h2>
          <p className="text-sm lg:text-base">
            Please wait a moment while we verify your payment
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={30}
          height={30}
          viewBox="0 0 64 64"
        >
          <path
            fill="#ffdd15"
            d="M63.37 53.52Q49.287 27.795 35.2 2.07a3.687 3.687 0 0 0-6.522 0C19.289 19.22 9.892 36.37.508 53.52c-1.453 2.649.399 6.083 3.258 6.083h56.35c1.584 0 2.648-.853 3.203-2.01c.698-1.102.885-2.565.055-4.075"
          ></path>
          <path
            fill="#1f2e35"
            d="m28.917 34.477l-.889-13.262q-.247-3.875-.246-5.565c0-1.534.4-2.727 1.202-3.588q1.207-1.285 3.175-1.286c1.583 0 2.646.551 3.178 1.646q.807 1.652.809 4.751q0 1.824-.198 3.708l-1.19 13.649q-.193 2.439-.827 3.739c-.426.871-1.128 1.301-2.109 1.301c-.992 0-1.69-.419-2.072-1.257c-.393-.841-.668-2.12-.833-3.836m3.072 18.217q-1.687.002-2.947-1.093q-1.26-1.093-1.26-3.058c0-1.143.4-2.12 1.202-2.921c.805-.806 1.786-1.206 2.951-1.206s2.153.4 2.977 1.206c.815.801 1.234 1.778 1.234 2.921c0 1.29-.419 2.308-1.246 3.044a4.25 4.25 0 0 1-2.911 1.107"
          ></path>
        </svg>
        <p className="font-light text[#535353] text-xs lg:text-sm">
          Please do not close the page until the status changes
        </p>
      </div>
    </div>
  );
};

export default Payment;
