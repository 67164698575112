import Button from 'components/ui/Button/Button';
import FloatingLabelInput from 'components/ui/Inputs/FloatingLabelInput';
import { usePageContext } from 'context/hooks/usePageContext';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { PAGE_SUB } from 'context/pageContext';
import useGetCardPaymentStatus from 'hooks/useGetCardPaymentStatus';
import React, { useState } from 'react';
import { checkResponse, debounce } from 'utils';
import axiosInstance from 'utils/axiosInstance';
import { validateAddress } from 'utils/validation';

const AddressAuth = () => {
  const { changeSub, changePage } = usePageContext();
  const { ceviantTransactionId } = useTransactionContext();
  const [addressForm, setAddressForm] = useState({
    address: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [addressErrors, setAddressErrors] = useState<Record<string, string>>(
    {}
  );
  const [isAddressSubmitting, setIsAddressSubmitting] = useState(false);
  const { triggerFetch, error } = useGetCardPaymentStatus(ceviantTransactionId);

  const validateAddressFormDebounced = debounce((form: typeof addressForm) => {
    const { errors } = validateAddress(form);
    setAddressErrors(errors);
  }, 500);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      validateAddressFormDebounced(updatedForm);
      return updatedForm;
    });
  };

  const handleAddressSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validateAddress(addressForm);
    if (validation.isValid) {
      try {
        setIsAddressSubmitting(true);

        const payload = {
          address: addressForm.address,
          city: addressForm.city,
          state: addressForm.state,
          zipCode: addressForm.zipCode,
          reference: ceviantTransactionId,
        };

        const response = await axiosInstance.post(
          '/payments/addressAuths',
          payload
        );

        if (response.data) {
          checkResponse(response.data.response, changeSub, changePage);
        }
      } catch (error) {
        triggerFetch();
      } finally {
        setIsAddressSubmitting(false);
      }
    } else {
      setAddressErrors(validation.errors);
    }
  };

  if (error) {
    changeSub(PAGE_SUB.ERROR);
  }

  return (
    <div className="space-y-6 text-center py-14">
      <img src="/icons/authenticate.svg" alt="" className="m-auto w-11" />
      <h3 className="text-[#535353] text-lg font-semibold">
        Please input your address to continue your payment
      </h3>
      <form className="flex flex-col gap-5 px-4" onSubmit={handleAddressSubmit}>
        <FloatingLabelInput
          label="Address"
          type="text"
          value={addressForm.address}
          onChange={handleAddressChange}
          name="address"
        />
        {addressErrors.address && (
          <p className="text-red-500 text-sm mt-1">{addressErrors.address}</p>
        )}
        <FloatingLabelInput
          label="City"
          type="text"
          value={addressForm.city}
          onChange={handleAddressChange}
          name="city"
        />
        {addressErrors.city && (
          <p className="text-red-500 text-sm mt-1">{addressErrors.city}</p>
        )}
        <FloatingLabelInput
          label="State"
          type="text"
          value={addressForm.state}
          onChange={handleAddressChange}
          name="state"
        />
        {addressErrors.state && (
          <p className="text-red-500 text-sm mt-1">{addressErrors.state}</p>
        )}
        <FloatingLabelInput
          label="Zip Code"
          type="number"
          value={addressForm.zipCode}
          onChange={handleAddressChange}
          name="zipCode"
        />
        {addressErrors.zipCode && (
          <p className="text-red-500 text-sm mt-1">{addressErrors.zipCode}</p>
        )}
        <div className="flex gap-3 w-full m-auto">
          <Button
            type="submit"
            variant="primary"
            disabled={isAddressSubmitting}
          >
            Authenticate
          </Button>
          <Button variant="outline" onClick={() => changeSub(PAGE_SUB.CARD)}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddressAuth;
