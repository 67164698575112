import Icon from '../ui/Icon/Icon';

import { ReactComponent as TimeoutSVG } from '../../assets/icons/timeout.svg';
import { ReactComponent as Retry } from '../../assets/icons/retry.svg';
import Button from '../ui/Button/Button';
import { useErrorContext } from 'context/hooks/useErrorContext';
import { usePageContext } from 'context/hooks/usePageContext';
import { PAGE_SUB, PAGES } from 'context/pageContext';

const Timeout = () => {
  const { changePage, changeSub, page } = usePageContext();
  const { clearError } = useErrorContext();

  const handleChange = () => {
    clearError();

    if (page === PAGES.CARD) {
      changeSub(PAGE_SUB.CARD);
    }

    if (page === PAGES.TRANSFER) {
      changePage(PAGES.TRANSFER);
    }
  };

  return (
    <div className="space-y-8 text-center py-14 flex flex-col items-center px-4">
      <Icon svg={TimeoutSVG} size={70} />
      <div>
        <h2>Payment Session Expired</h2>
        <p className="text-sm">
          Looks like your payment session has expired. No worries, let's start
          over
        </p>
      </div>
      <div className="flex gap-3 w-full m-auto">
        <Button
          onClick={() => handleChange()}
          type="submit"
          variant="primary"
          className="space-y-4 text-center my-3"
        >
          <Icon svg={Retry} />
          Retry
        </Button>
        <Button
          variant="outline"
          onClick={() =>
            window.parent.postMessage({ message: 'closeCeviantSDK' }, '*')
          }
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default Timeout;
