import { useErrorContext } from 'context/hooks/useErrorContext';
import { usePageContext } from 'context/hooks/usePageContext';
import { PAGE_SUB, PAGES } from 'context/pageContext';
import { useState, useEffect, useCallback } from 'react';
import axiosInstance from 'utils/axiosInstance';

const useGetCardPaymentStatus = (paymentId: string) => {
  const { setError: updateError } = useErrorContext();
  const { page, changePage, changeSub } = usePageContext();
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);

  const triggerFetch = useCallback(() => {
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    if (!shouldFetch) return;

    const fetchPaymentStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `/payments/status/${paymentId}`
        );
        if (response.data.response === 'APPROVED') {
          if (page === 'Card') {
            changePage(PAGES.AUTHENTICATING);
            changeSub(PAGE_SUB.SUCCESS);
          }
        } else {
          updateError(response.data);
          setStatus(response.data.response);
          setError(true);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
        setShouldFetch(false);
      }
    };

    fetchPaymentStatus();
  }, [changePage, changeSub, page, paymentId, shouldFetch, updateError]);

  return { status, loading, error, triggerFetch };
};

export default useGetCardPaymentStatus;
