import { createContext, ReactNode, useCallback, useState } from 'react';
export enum PAGES {
  CARD = 'Card',
  TRANSFER = 'Transfer',
  AUTHENTICATING = 'Authenticating',
  SUCCESS = 'Success',
  LOADING = 'Loading',
}

export enum PAGE_SUB {
  ERROR = 'Error',
  LOADING = 'Loading',
  TRANSFER = 'Transfer',
  CARD = 'Card',
  SUCCESS = 'Success',
  DECLINED = 'Declined',
  FAILED = 'Failed',
  PAYING = 'Paying',
  CANCELLED = 'Cancelled',
  INITIALISED = 'Initialised',
  AUTHENTICATING = 'Authenticating',
  PROCESSING = 'Processing',
  TIMEOUT = 'Timeout',
  REFUNDED = 'Refunded',
  REFUND_IN_PROGRESS = 'RefundInProgress',
  ADDRESS_AUTH = 'AddressAuth',
  PHONE_AUTH = 'PhoneAuth',
  OTP_AUTH = 'OtpAuth',
  PIN_AUTH = 'PinAuth',
  BIRTHDAY_AUTH = 'BirthdayAuth',
}

type PageContextType = {
  page: PAGES;
  sub: PAGE_SUB;
  changePage: (page?: PAGES) => void;
  changeSub: (sub: PAGE_SUB) => void;
};

export const PageContext = createContext<PageContextType>({
  page: PAGES.CARD,
  sub: PAGE_SUB.CARD,
  changePage: () => {},
  changeSub: () => {},
});

const PageProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useState<PAGES>(PAGES.CARD);
  const [sub, setSub] = useState<PAGE_SUB>(PAGE_SUB.CARD);

  const changePage = useCallback(
    (page?: PAGES) => {
      if (page) {
        setPage(page);
      } else {
        setPage(PAGES.CARD);
      }
    },
    [setPage]
  );

  const changeSub = useCallback(
    (sub: PAGE_SUB) => {
      if (sub) {
        setSub(sub);
      }
    },
    [setSub]
  );

  return (
    <PageContext.Provider value={{ page, sub, changePage, changeSub }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
