import { useState } from 'react';
import { usePageContext } from 'context/hooks/usePageContext';
import Button from 'components/ui/Button/Button';
import FloatingLabelInput from 'components/ui/Inputs/FloatingLabelInput';
import { validatePhone } from 'utils/validation';
import axiosInstance from 'utils/axiosInstance';
import { checkResponse } from 'utils';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import useGetCardPaymentStatus from 'hooks/useGetCardPaymentStatus';
import { PAGE_SUB } from 'context/pageContext';

const PhoneAuth = () => {
  const { ceviantTransactionId } = useTransactionContext();
  const { changeSub, changePage } = usePageContext();
  const [phoneNumber, setPhoneNumber] = useState({ phone: '' });
  const [phoneErrors, setPhoneErrors] = useState<Record<string, string>>({});
  const [isPhoneSubmitting, setIsPhoneSubmitting] = useState(false);
  const { triggerFetch, error } = useGetCardPaymentStatus(ceviantTransactionId);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber({ ...phoneNumber, [e.target.name]: e.target.value });
  };

  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validatePhone(phoneNumber);
    if (validation.isValid) {
      try {
        setIsPhoneSubmitting(true);
        const payload = {
          phone: phoneNumber.phone,
          reference: ceviantTransactionId,
        };

        const response = await axiosInstance.post(
          '/payments/phoneAuths',
          payload
        );

        if (response.data) {
          checkResponse(response.data.response, changeSub, changePage);
        }
      } catch (error) {
        triggerFetch();
      } finally {
        setIsPhoneSubmitting(false);
      }
    } else {
      setPhoneErrors(validation.errors);
    }
  };

  if (error) {
    changeSub(PAGE_SUB.ERROR);
  }

  return (
    <div className="space-y-6 text-center py-14">
      <img src="/icons/authenticate.svg" alt="" className="m-auto w-11" />
      <h3 className="text-[#535353] text-lg font-semibold">
        Please input your phone number to continue your payment
      </h3>
      <form className="flex flex-col gap-5 px-4" onSubmit={handleSendCode}>
        <FloatingLabelInput
          label="PHONE NUMBER"
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          value={phoneNumber.phone}
          onChange={handlePhoneChange}
          name="phone"
        />
        {phoneErrors.otp && (
          <p className="text-red-500 text-sm mt-1">{phoneErrors.otp}</p>
        )}
        <div className="flex gap-3 w-full m-auto">
          <Button type="submit" variant="primary" disabled={isPhoneSubmitting}>
            Authenticate
          </Button>
          <Button variant="outline" onClick={() => changeSub(PAGE_SUB.CARD)}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PhoneAuth;
