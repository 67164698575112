import { PAGE_SUB, PAGES } from 'context/pageContext';
import { v4 as uuidv4 } from 'uuid';

export const formatCurrency = (
  amount: number | string | undefined | null,
  currency: string
): string => {
  if (!amount) return 'N/A';
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const getUniqueKey = () => {
  return uuidv4();
};

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const checkResponse = (
  response: string,
  updateSub: (sub: PAGE_SUB) => void,
  updatePage: (sub: PAGES) => void
) => {
  switch (response) {
    case 'SEND_PIN':
      updateSub(PAGE_SUB.PIN_AUTH);
      return;
    case 'SEND_OTP':
      updateSub(PAGE_SUB.OTP_AUTH);
      return;
    case 'SEND_BIRTHDAY':
      updateSub(PAGE_SUB.BIRTHDAY_AUTH);
      return;
    case 'SEND_PHONE':
      updateSub(PAGE_SUB.PHONE_AUTH);
      return;
    case 'APPROVED':
      updatePage(PAGES.AUTHENTICATING);
      updateSub(PAGE_SUB.SUCCESS);
      return;
    case 'PENDING':
      updateSub(PAGE_SUB.PAYING);
      return;
    case 'TIMED_OUT':
      updateSub(PAGE_SUB.TIMEOUT);
      return;
    case 'UNKNOWN':
      updateSub(PAGE_SUB.ERROR);
      return;
    case 'REFUNDED':
      updateSub(PAGE_SUB.REFUNDED);
      return;
    case 'REFUND_IN_PROGRESS':
      updateSub(PAGE_SUB.REFUND_IN_PROGRESS);
      return;
    default:
      console.log('Unknown status!!');
      return;
  }
};

export const getTransferStatus = (
  status: string,
  updateSub: (sub: string) => void,
  updatePage: (sub: string) => void
) => {
  switch (status) {
    case 'APPROVED':
      updatePage('Authenticating');
      updateSub('Success');
      return;
    case 'DECLINED':
      updateSub('Declined');
      return;
    case 'FAILED':
      updateSub('Failed');
      return;
    case 'IN_PROGRESS':
      updateSub('Paying');
      return;
    case 'CANCELLED':
      updateSub('Cancelled');
      return;
    case 'INITIALISED':
      return;
    case 'SETTLED':
      updatePage('Authenticating');
      updateSub('Success');
      return;
    case 'PENDING':
      updateSub('Paying');
      return;
    case 'TIMED_OUT':
      updateSub('Timeout');
      return;
    case 'UNKNOWN':
      updateSub('Error');
      return;
    case 'REFUNDED':
      updateSub('Refunded');
      return;
    case 'REFUND_IN_PROGRESS':
      updateSub('RefundInProgress');
      return;
    default:
      console.log('Unknown status!!');
      return;
  }
};
